import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { isEmpty } from 'lodash'

import base from '../sagas/base'
import Layout from '../components/layout'
import ShorthandLayout from '../components/shorthandLayout'
import SEO from '../components/seo'
import Article from '../components/article/article'
import ShortHandArticle from '../components/article/shortHandArticle'
import { GridError } from '../components/grid/grid.styled'
import { GridLoader, Spinner } from '../components/shorthand/shorthand.styled'

const Preview = ({ data, location }) => {
  const [initialPageLoad, setInitialPageLoad] = useState(true)
  const [articleFetching, setArticleFetching] = useState(false)
  const [article, setArticle] = useState(null)
  const [hasError, setHasError] = useState(false)
  const loc = useLocation()

  useEffect(() => {
    setInitialPageLoad(false)
    if (!article) {
      getData()
    }
  }, [])

  const articleId = queryString.parse(loc.search).id || null
  const lang = queryString.parse(loc.search).lang || 'ar'
  const spaceId = queryString.parse(loc.search).spaceId || null
  const accessToken = queryString.parse(loc.search).accessToken
  const articleType = queryString.parse(loc.search).articleType || ''

  const getData = async () => {
    if (accessToken == null) {
      setHasError(true)
      return null
    }

    setArticleFetching(true)

    const hasAccess = await base.testAdminToken(accessToken)

    if (!hasAccess) {
      setArticleFetching(false)
      setHasError(true)
      return null
    }

    try {
      const result = await base.getPreview(accessToken, {
        id: articleId,
        lang,
        spaceId
      })
      const authors = result.data.data.authors.map((author) => {
        return {
          name: author.name ? author.name.ar : null,
          avatar: author.avatar ? author.avatar.ar : null,
          email: author.email ? author.email.ar : null,
          id: author.id,
          accessToken: accessToken
        }
      })

      const tagData =
        result.data.data && result.data.data.tags
          ? result.data.data.tags.map((tag) => {
              return {
                name: tag.name_ar,
                id: tag.id
              }
            })
          : []

      const articleContent = { ...result.data.data }

      articleContent.isShortHandArticle =
        articleContent.articleType && articleContent.articleType === 'shorthand'

      if (!isEmpty(articleContent.subLiveIds)) {
        articleContent.articleType = 'live'
        articleContent.isLiveArticle = true
        articleContent.subArticles = []
        articleContent.subLiveShareUrls = []
        articleContent.coverageSummary = articleContent.content

        const subArticlesResponseData = await base.getSubArticlesPreview(
          accessToken,
          {
            subArticleIds: articleContent.subLiveIds,
            lang,
            spaceId
          }
        )
        if (!isEmpty(subArticlesResponseData)) {
          const subArticles = []
          subArticlesResponseData.forEach((subArticle) => {
            if (subArticle.status === 'fulfilled') {
              const data = subArticle.value.data.data
              subArticles.push(data)
            }
          })
          articleContent.subArticles = subArticles
        }

        setArticle({ ...articleContent, authors: authors, tags: tagData })
        setArticleFetching(false)
      } else {
        setArticle({ ...articleContent, authors: authors, tags: tagData })
        setArticleFetching(false)
      }
    } catch (ex) {
      console.log('error', ex)
      setArticleFetching(false)
      setHasError(true)
      return null
    }
  }

  const renderLoader = (loadingMessage) => {
    return (
      <React.Fragment>
        <GridLoader>
          <Spinner />
        </GridLoader>
        {loadingMessage && (
          <div
            style={{
              textAlign: 'center',
              marginTop: 20
            }}
          >
            {loadingMessage}
          </div>
        )}
      </React.Fragment>
    )
  }

  const renderLayout = () => {
    if (loc && loc.search !== '') {
      if (
        articleType === 'shorthand' ||
        (article && article.isShortHandArticle)
      ) {
        return (
          <ShorthandLayout>
            {hasError && (
              <GridError>Error while Fetching shorthand Article</GridError>
            )}

            {articleFetching &&
              renderLoader('Loading shorthand article preview')}

            {!hasError && article && article.content && (
              <ShortHandArticle article={article} />
            )}
          </ShorthandLayout>
        )
      } else if (article) {
        return (
          <Layout location={location} title={''} lang={lang}>
            <SEO title="Preview" lang="en" location={location} />

            {!hasError && (
              <Article
                isPreview={true}
                article={article}
                disableGrid={true}
                location={location}
                spaceId={spaceId}
              />
            )}
          </Layout>
        )
      } else {
        return (
          <>
            {articleFetching && renderLoader('Loading article preview')}

            {hasError && <GridError>Access denied</GridError>}
          </>
        )
      }
    }

    return null
  }

  // do not load the layout until we have the query param in loc , so we know which layout to load

  return (
    <React.Fragment>
      {initialPageLoad ? renderLoader() : renderLayout()}
    </React.Fragment>
  )
}

export default Preview
